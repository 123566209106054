export function getSimplifiedPosts(posts, options = {}) {
  return posts.map((post) => ({
    id: post.node.id,
    date: post.node.frontmatter.date,
    slug: post.node.fields.slug,
    tags: post.node.frontmatter.tags,
    categories: post.node.frontmatter.categories,
    title: options.shortTitle
      ? post.node.frontmatter.shortTitle
      : post.node.frontmatter.title,
    description: post.node.frontmatter.description,
    ...(options.thumbnails && {
      thumbnail: post.node.frontmatter?.thumbnail?.childImageSharp?.fixed,
    }),
  }))
}

export function getTaxonomyFromPosts(posts, taxonomy) {
  return posts
    .reduce((acc, post) => {
      return [...new Set([...acc, ...(post[taxonomy] || [])])]
    }, [])
    .sort()
}

export function slugify(string) {
  return (
    string &&
    `${string}`
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  )
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getGiscusTheme() {
  const preferredTheme = localStorage.getItem("theme");
  const giscusTheme = preferredTheme == null || preferredTheme == "light" ? "light" : "dark";
  return giscusTheme;
}

export function setGiscusTheme() {
  function sendMessage(message) {
  const iframe = document.querySelector('iframe.giscus-frame');
  if (!iframe) return;
  iframe.contentWindow.postMessage({ giscus: message }, 'https://giscus.app');
}
  sendMessage({
    setConfig: {
  theme: getGiscusTheme() == "light" ? "dark": "light",
    },
  });
}

export function appendComments() {
  const commentDiv = document.getElementById('append-comments-here')
  const commentScript = document.createElement('script')
  const commentPesan = document.createElement('div')
  // const theme = localStorage.getItem('theme')

  commentScript.async = true
  commentScript.src = 'https://giscus.app/client.js'
  commentScript.setAttribute('data-repo', 'khoirulaksara/komentar')
  commentScript.setAttribute('data-repo-id', 'R_kgDOIsJatg')
  commentScript.setAttribute('data-category', 'Komentar Blog')
  commentScript.setAttribute('data-category-id', 'DIC_kwDOIsJats4CTW2D')
  commentScript.setAttribute('data-mapping', 'pathname')
  commentScript.setAttribute('data-strict', '0')
  commentScript.setAttribute('data-reactions-enabled', '1')
  commentScript.setAttribute('data-emit-metadata', '0')
  commentScript.setAttribute('data-input-position', 'bottom')
  commentScript.setAttribute('data-loading', 'lazy')
  commentScript.setAttribute('data-theme', getGiscusTheme())
  commentScript.setAttribute('data-lang', 'id')
  commentScript.setAttribute('crossorigin', 'anonymous')

  const toggle = document.querySelector('.theme-toggle');
  if (toggle) {
  toggle.addEventListener('click', setGiscusTheme);
  }

  if (!commentDiv.firstChild) {
    commentDiv.appendChild(commentScript)
  } else {
    console.error("Giscus error")
  }
}

export function getFormattedDate(date) {
  const dateArr = date.split(' ')
  if (dateArr[1].startsWith('0')) {
    dateArr[1] = dateArr[1].slice(1, 2)
  } else {
    dateArr[1] = dateArr[1].slice(0, 12)
  }
  dateArr[1] += ' '

  return dateArr.join(' ')
}
